import { assertUnreachable, Maybe } from "@deltagreen/utils"
import { createNavigation } from "next-intl/navigation"
import { defineRouting } from "next-intl/routing"
import { getLocale } from "next-intl/server"

export const DEFAULT_LOCALE = "cs"

export const routing = defineRouting({
  // A list of all locales that are supported
  locales: ["cs", "en", "ro"],

  // Used when no locale matches
  defaultLocale: DEFAULT_LOCALE,
})

// Lightweight wrappers around Next.js' navigation APIs
// that will consider the routing configuration
const { Link, redirect, usePathname, useRouter, getPathname } = createNavigation(routing)

const redirectWithCurrentLocale = async (href: string): Promise<never> => {
  const locale = await getLocale()
  const res = redirect({
    href,
    locale,
  })

  assertUnreachable(res)
}

export function isValidLocale(locale: Maybe<string>) {
  if (!locale) {
    return false
  }
  return (routing.locales as unknown as string[]).includes(locale)
}

export { getPathname, Link, redirect, redirectWithCurrentLocale, usePathname, useRouter }
